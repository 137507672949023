import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Block, Content, Flex, FlexSpacer, Input, Yoga } from 'gerami'

import { BlogPageQuery } from '../../../../graphql-types'
import { useSearch } from '../../../shared/contexts/search-index-context/search-index-context'
import useSearchMatch from '../../../shared/hooks/use-search-match/use-search-match'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import BlogPostCard from '../../components/blog-post-card/blog-post-card'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'

type BlogProps = {}

const Blog: React.FC<BlogProps> = ({}) => {
  const data = useStaticQuery<BlogPageQuery>(query)

  const [term, setTerm] = useState(``)
  const results = (useSearch(term, { expand: true }) || []).filter(
    result => result.template === 'blog-post'
  )

  const featuredBlog = useSearchMatch(
    data.featuredBlog.edges.map(edge => edge.node),
    term.length ? results : undefined
  )
  const otherBlog = useSearchMatch(
    data.otherBlog.edges.map(edge => edge.node),
    term.length ? results : undefined
  )

  return (
    <>
      <SEO title="Blog" />

      <Layout>
        <Content transparent size="4XL">
          <Block first last>
            <Flex>
              <h1 className="h0">Blog</h1>

              <FlexSpacer />

              <div
                className="margin-left-very-big padding-top-big full-width"
                style={{ maxWidth: 420 }}
              >
                <Input
                  type="search"
                  value={term}
                  onChange={e => setTerm(e.target.value)}
                  placeholder="Search"
                  className="middle full-width"
                />
              </div>
            </Flex>

            {featuredBlog.length <= 0 ? null : (
              <>
                <h4 className="subtitle">
                  Featured Blog Post
                  {featuredBlog.length === 1 ? `` : `s`}
                  {term ? (
                    <>
                      {' '}
                      Matching <q>{term}</q>
                    </>
                  ) : null}
                </h4>

                <hr />

                <Yoga maxCol={2} className="margin-bottom-big">
                  {featuredBlog.map((node, i) => (
                    <BlogPostCard
                      key={i}
                      slug={node.fields?.slug!}
                      thumbnail={node.frontmatter?.thumbnail}
                      title={node.frontmatter?.title!}
                      date={node.frontmatter?.date!}
                      excerpt={node.excerpt}
                    />
                  ))}
                </Yoga>
              </>
            )}

            <h4 className="subtitle">
              {featuredBlog.length > 0 ? `Other` : `All`} Blog Post
              {otherBlog.length === 1 ? `` : `s`}
              {term ? (
                <>
                  {' '}
                  Matching <q>{term}</q>
                </>
              ) : null}
            </h4>

            <hr />

            {otherBlog.length <= 0 ? (
              <div className="margin-vertical-big">
                No {featuredBlog.length > 0 ? `more ` : ``}
                blog found.
              </div>
            ) : (
              <Yoga maxCol={3}>
                {otherBlog.map((node, i) => (
                  <BlogPostCard
                    key={i}
                    slug={node.fields?.slug!}
                    thumbnail={node.frontmatter?.thumbnail}
                    title={node.frontmatter?.title!}
                    date={node.frontmatter?.date!}
                    excerpt={node.excerpt}
                  />
                ))}
              </Yoga>
            )}
          </Block>
        </Content>

        <EmailRegistration />
      </Layout>
    </>
  )
}

export default Blog

export const query = graphql`
  query BlogPage {
    featuredBlog: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "blog-post" }, isFeatured: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail
            title
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
    otherBlog: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "blog-post" }, isFeatured: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail
            title
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
