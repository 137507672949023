import React from 'react'
import GatsbyImage from 'gatsby-image'
import { FaRegNewspaper } from 'react-icons/fa'

import './blog-post-card.scss'
import Anchor from '../../../shared/components/anchor/anchor'

type BlogPostCardProps = {
  slug: string
  thumbnail?: string | null
  title: string
  date: string
  excerpt?: string | null
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({
  slug,
  thumbnail,
  title,
  date,
  excerpt
}) => {
  return (
    <Anchor to={slug} className="blog-post-card">
      <GatsbyImage
        className="blog-post-card-image bg-whitish"
        fluid={{
          aspectRatio: 16 / 9,
          src: `${thumbnail}?nf_resize=fit&w=512`,
          srcSet: `
            ${thumbnail}?nf_resize=fit&w=512 512w,
            ${thumbnail}?nf_resize=fit&w=320 320w,
            ${thumbnail}?nf_resize=fit&w=160 160w
          `,
          sizes: `
            (min-width: 36em) 33.3vw,
            100vw
          `
        }}
      />
      <div className="blog-post-card-overlay">
        <FaRegNewspaper className="blog-post-card-overlay-icon" />
        <span className="blog-post-card-overlay-label">Read Article</span>
      </div>
      <h5 className="blog-post-card-title">
        {title} <span className="subtitle font-M"> — {date}</span>
      </h5>
      <div className="blog-post-card-excerpt fg-blackish">{excerpt}</div>
    </Anchor>
  )
}

export default BlogPostCard
